<template>
  <div>
<!--    <a-card>-->
<!--      <a-card-grid style="width:50%;text-align:center" :hoverable="false" :bordered="false">-->
<!--        Content-->
<!--      </a-card-grid>-->
<!--      <a-card-grid style="width:50%;text-align:center" :hoverable="false" :bordered="false">-->
<!--        Content-->
<!--      </a-card-grid>-->
        <a-form-model
          ref="pushAddExpForm"
          :model="form"
          :rules="rules"
        >
          <hr>
          <a-row>
            <a-col :span="6">
            </a-col>
            <a-col class="text-right" :span="18">
              <a-button type="primary" @click="onSubmit">
                Create
              </a-button>
              <!-- <a-button style="margin-left: 10px;" @click="resetForm">
                Reset
              </a-button>
              <a-button style="margin-left: 10px;" @click="closeModal">
                Cancel
              </a-button> -->
              <CustomButton style="margin-left: 10px;" @click="resetForm">
                Reset
              </CustomButton>
              <CustomButton style="margin-left: 10px;" @click="closeModal">
                Cancel
              </CustomButton>
            </a-col>
          </a-row>
        </a-form-model>
<!--    </a-card>-->
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import pushmix from '@/services/pushmix.js'
import CustomButton from '@/components/custom/button/CustomButton'
export default {
  name: 'addPush',
  mixins: [pushmix],
  components: {
    CustomButton,
  },
  data() {
    return {
      // labelCol: { span: 24 },
      // wrapperCol: { span: 24 },
      indeterminateApps: false,
      checkAllApps: false,
      checkedAppList: [],
      form: {
        title: 'We’re blasting off 🚀',
        image_path: 'resources/images/push_img_default.jpg',
        starts_at: undefined,
        ends_in: undefined,
        platform: [],
        applications: [],
        description: 'OneSignal announces 500% growth, delivering 2 trillion messages annually.',
        status: 0,
      },
      rules: {
        title: [
          { required: true, message: 'Please input title for notification', trigger: 'blur' },
          { min: 6, message: 'Title length should be 6 minimum', trigger: 'blur' },
        ],
        starts_at: [{ required: true, message: 'Please pick a date to start notification', trigger: 'change' }],
        ends_in: [{ required: true, message: 'Please pick a date to stop notification', trigger: 'change' }],
        applications: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one application',
            trigger: 'change',
          },
        ],
        platform: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one platform',
            trigger: 'change',
          },
        ],
        description: [{ required: true, message: 'Please input description', trigger: 'blur' }, { min: 6, message: 'Description length should be 6 minimum', trigger: 'blur' }],
        image_path: [{ required: true, message: 'Please input image link', trigger: 'blur' }],
      },
    }
  },
  methods: {
    platformChange() {
      this.form.platform.sort()
      // console.log(this.form.platform)
    },
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(newPush) {
      this.$emit('submitModalFunction', newPush)
    },
    startDateChange(value, dateString) {
      this.form.starts_at = dateString
    },
    endDateChange(value, dateString) {
      this.form.ends_in = dateString
    },
    onSubmit() {
      this.$refs.pushAddExpForm.validate(valid => {
        if (valid) {
          // alert('submit!')
          this.createPush()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.pushAddForm.resetFields()
    },
    onCheckAllAppsChange(e) {
      Object.assign(this, {
        checkedAppList: e.target.checked ? this.apps.map(item => item.application_id) : [],
        indeterminateApps: false,
        checkAllApps: e.target.checked,
      })
      this.form.applications = this.checkedAppList
    },
    appsChange(checkedAppList) {
      const pushAppsKeys = this.apps.map(record => record.application_id)
      this.indeterminateApps = !!checkedAppList.length && checkedAppList.length < pushAppsKeys.length
      this.checkAllApps = checkedAppList.length === pushAppsKeys.length
      this.form.applications = checkedAppList
      console.log('selected apps objects')
      console.log(this.form.applications)
    },
    createPush() {
      const url = '/admin/notifications'
      this.form.platform.sort()
      return apiClient.post(url, this.form).then((response) => {
        const newPush = response.data.data
        console.log(response)
        // console.log(this.form)
        this.$notification.success({
          message: 'Notification successfully created',
          description: '',
        })
        this.resetForm()
        this.submitModal(newPush)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while creating new push-notification',
          description: error.message,
        })
      })
    },
  },
}
</script>

<style scoped>
  .ant-form-item-label {
    padding: 0px !important;
  }
</style>
