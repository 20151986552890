<template>
  <div>
<!--    <a-card>-->
<!--      <a-card-grid style="width:50%;text-align:center" :hoverable="false" :bordered="false">-->
<!--        Content-->
<!--      </a-card-grid>-->
<!--      <a-card-grid style="width:50%;text-align:center" :hoverable="false" :bordered="false">-->
<!--        Content-->
<!--      </a-card-grid>-->
        <a-form-model
          ref="pushAddForm"
          :model="form"
          :rules="rules"
        >
        <a-row>
        <a-col :span="13">
          <a-form-model-item label="Platform" prop="platform">
            <a-checkbox-group v-model="form.platform" @change="platformChange">
              <a-checkbox value="ios" name="platform">
                iOS
              </a-checkbox>
              <a-checkbox value="android" name="platform">
                Android
              </a-checkbox>
              <a-checkbox value="web" name="platform">
                Web
              </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
          <a-form-model-item ref="title" label="Title" prop="title">
              <a-input v-model="form.title" placeholder="Input Title" />
            </a-form-model-item>
            <a-form-model-item ref="description" label="Description" prop="description">
              <a-input v-model="form.description" type="textarea" placeholder="Input Description" />
            </a-form-model-item>
            <a-form-model-item ref="imglink" label="Image link" prop="image_path">
              <a-input v-model="form.image_path" placeholder="Input Image Link" />
            </a-form-model-item>
          <div class="d-flex">
            <a-form-model-item label="Start date" required prop="starts_at" class="pr-2">
              <a-date-picker
                v-model="form.starts_at"
                show-time
                format="YYYY-MM-DD HH:mm:ss"
                type="date"
                placeholder="Pick a start date"
                style="width: 100%;"
                @change="startDateChange"
              />
            </a-form-model-item>
            <a-form-model-item label="End date" required prop="ends_in" class="pl-2">
              <a-date-picker
                v-model="form.ends_in"
                show-time
                format="YYYY-MM-DD HH:mm:ss"
                type="date"
                placeholder="Pick an End date"
                style="width: 100%;"
                @change="endDateChange"

              />
            </a-form-model-item>
          </div>
          <div class="float-left"><p class="font-size-16 font-weight-light">Apps</p></div>
          <div>
            <a-checkbox :indeterminate="indeterminateApps" :checked="checkAllApps" @change="onCheckAllAppsChange" class="pl-3">
              Select all
            </a-checkbox>
          </div>
          <a-form-model-item prop="applications" style="clear:both;">
            <a-checkbox-group v-model="checkedAppList" @change="appsChange">
              <a-checkbox v-for="app in apps" :value="app.application_id" name="applications" :key="app.application_id" style="max-width: 220px" @change="appsChange" class="ml-0 pr-2">
                {{ app.name }}
                <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="text-success" />
                <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
                <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="text-default" />
              </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
        </a-col>
          <a-col :span="1">
          </a-col>
          <a-col :span="10">
            <a-card size="small" style="width: 360px; border-radius: 10px">
              <div slot="title" class="text-uppercase text-gray-5"><a-avatar size="small" src="resources/images/logo-olimpbet-app.svg" class="mr-2" />OLIMPBET</div>
              <div slot="extra" class="text-gray-5">3 min ago</div>
              <div class="d-flex">
                <div class="mr-auto">
                  <div class="font-weight-bold">{{ form.title }}</div>
                  <div>{{ form.description }}</div>
                </div>
                <div><a-avatar size="large" shape="square" :src="form.image_path" /></div>
              </div>
            </a-card>
            <a-card size="small" style="width: 360px; border-radius: 10px" class="mt-5">
              <div slot="title" class="text-uppercase text-gray-5"><a-avatar size="small" src="resources/images/logo-olimpbet-app.svg" class="mr-2" />OLIMPBET</div>
              <div slot="extra" class="text-gray-5 font-size-24 font-weight-bold"><a-icon type="ellipsis" /></div>
              <div>
                <img :src="form.image_path" width="100%" style="max-height: 200px" />
              </div>
              <div class="content mt-2">
                <div class="mr-auto">
                  <div class="font-weight-bold">{{ form.title }}</div>
                  <div>{{ form.description }}</div>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
          <hr>
          <a-row style="margin-top:0 !important;">
            <a-col class="text-left" :span="24">
              <a-button type="primary" class="float-right" @click="onSubmit">
                Create banner
              </a-button>
              <!-- <a-button @click="resetForm">
                Reset
              </a-button>
              <a-button style="margin-left: 1rem;" @click="closeAddModal">
                Cancel
              </a-button> -->
              <CustomButton @click="resetForm">
                Reset
              </CustomButton>
              <CustomButton style="margin-left: 1rem;" @click="closeAddModal">
                Cancel
              </CustomButton>
            </a-col>
          </a-row>
        </a-form-model>
<!--    </a-card>-->
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import pushmix from '@/services/pushmix.js'
import CustomButton from '@/components/custom/button/CustomButton'
export default {
  name: 'addPush',
  mixins: [pushmix],
  components: {
    CustomButton,
  },
  data() {
    return {
      // labelCol: { span: 24 },
      // wrapperCol: { span: 24 },
      indeterminateApps: false,
      checkAllApps: false,
      checkedAppList: [],
      form: {
        title: 'We’re blasting off 🚀',
        image_path: 'resources/images/push_img_default.jpg',
        starts_at: undefined,
        ends_in: undefined,
        platform: [],
        applications: [],
        description: 'OneSignal announces 500% growth, delivering 2 trillion messages annually.',
        status: 0,
      },
      rules: {
        title: [
          { required: true, message: 'Please input title for notification', trigger: 'blur' },
          { min: 6, message: 'Title length should be 6 minimum', trigger: 'blur' },
        ],
        starts_at: [{ required: true, message: 'Please pick a date to start notification', trigger: 'change' }],
        ends_in: [{ required: true, message: 'Please pick a date to stop notification', trigger: 'change' }],
        applications: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one application',
            trigger: 'change',
          },
        ],
        platform: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one platform',
            trigger: 'change',
          },
        ],
        description: [{ required: true, message: 'Please input description', trigger: 'blur' }, { min: 6, message: 'Description length should be 6 minimum', trigger: 'blur' }],
        image_path: [{ required: true, message: 'Please input image link', trigger: 'blur' }],
      },
    }
  },
  methods: {
    platformChange() {
      this.form.platform.sort()
      // console.log(this.form.platform)
    },
    closeAddModal() {
      this.$emit('closeModalFunction')
    },
    submitAddModal(newPush) {
      this.$emit('submitModalFunction', newPush)
    },
    startDateChange(value, dateString) {
      this.form.starts_at = dateString
    },
    endDateChange(value, dateString) {
      this.form.ends_in = dateString
    },
    onSubmit() {
      this.$refs.pushAddForm.validate(valid => {
        if (valid) {
          // alert('submit!')
          this.createPush()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.pushAddForm.resetFields()
      this.form.title = ''
      this.form.image_path = ''
      this.form.starts_at = undefined
      this.form.ends_in = undefined
      this.form.platform = []
      this.form.applications = []
      this.form.description = ''
    },
    onCheckAllAppsChange(e) {
      Object.assign(this, {
        checkedAppList: e.target.checked ? this.apps.map(item => item.application_id) : [],
        indeterminateApps: false,
        checkAllApps: e.target.checked,
      })
      this.form.applications = this.checkedAppList
    },
    appsChange(checkedAppList) {
      const pushAppsKeys = this.apps.map(record => record.application_id)
      this.indeterminateApps = !!checkedAppList.length && checkedAppList.length < pushAppsKeys.length
      this.checkAllApps = checkedAppList.length === pushAppsKeys.length
      this.form.applications = checkedAppList
      console.log('selected apps objects')
      console.log(this.form.applications)
    },
    createPush() {
      const url = '/admin/notifications'
      this.form.platform.sort()
      return apiClient.post(url, this.form).then((response) => {
        const newPush = response.data.data
        console.log(response)
        // console.log(this.form)
        this.$notification.success({
          message: 'Notification successfully created',
          description: '',
        })
        this.resetForm()
        this.submitAddModal(newPush)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while creating new push-notification',
          description: error.message,
        })
      })
    },
  },
}
</script>

<style scoped>
  .ant-form-item-label {
    padding: 0px !important;
  }
</style>
